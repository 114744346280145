<template>
  <main>
    <div class="container">
      <h2>Frequently Asked Questions</h2>
      <div class="accordion">
        <div class="accordion-item">
          <button id="accordion-button-1" aria-expanded="false">
            <span class="accordion-title">Which grades do Matric Live support?</span
            ><span class="icon" aria-hidden="true"></span>
          </button>
          <div class="accordion-content">
            <p>
              Matric Live currently supports grade 10, 11 and 12.
            </p>
          </div>
        </div>
        <div class="accordion-item">
          <button id="accordion-button-2" aria-expanded="false">
            <span class="accordion-title">Can I use Matric Live for free?</span
            ><span class="icon" aria-hidden="true"></span>
          </button>
          <div class="accordion-content">
            <p>
              Yes you can use Matric Live for free but some learning features will not be available to you.
            </p>
          </div>
        </div>
        <div class="accordion-item">
          <button id="accordion-button-3" aria-expanded="false">
            <span class="accordion-title">How much does it cost for Pro features?</span
            ><span class="icon" aria-hidden="true"></span>
          </button>
          <div class="accordion-content">
            <p>
              R 99.99 once off
            </p>
          </div>
        </div>
        <div class="accordion-item">
          <button id="accordion-button-4" aria-expanded="false">
            <span class="accordion-title">How can I make payments?</span
            ><span class="icon" aria-hidden="true"></span>
          </button>
          <div class="accordion-content">
            <p>
              You can make payments directly from the Matric Live app by using your Credit/ Debit Card, alternatively you can do a cash deposit to an account provided.
            </p>
          </div>
        </div>
        <div class="accordion-item">
          <button id="accordion-button-5" aria-expanded="false">
            <span class="accordion-title">Can I use one pro account on multiple devices?</span
            ><span class="icon" aria-hidden="true"></span>
          </button>
          <div class="accordion-content">
            <p>
              Pro accounts are restricted to one active device, if you sign in from a different device the other device will automatically logout.
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Help_And_Faq",
  mounted() {
    const items = document.querySelectorAll(".accordion button");

    function toggleAccordion() {
      const itemToggle = this.getAttribute("aria-expanded");

      for (var i = 0; i < items.length; i++) {
        items[i].setAttribute("aria-expanded", "false");
      }

      if (itemToggle == "false") {
        this.setAttribute("aria-expanded", "true");
      }
    }

    items.forEach((item) => item.addEventListener("click", toggleAccordion));
  },
};
</script>

<style lang="scss" scoped>
$bg: #fff;
$text: #7288a2;
$gray: #4d5974;
$lightgray: #e5e5e5;
$orange: mediumpurple;

* {
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

main {
  background-image: url("../../assets/careerspage/Desktop - 1.svg");
  background-size: auto;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Hind", sans-serif;
  background: $bg;
  color: $gray;
  display: flex;
  min-height: 100vh;
}

.container {
  margin: 0 auto;
  padding: 4rem;
  width: 48rem;
}
@media screen and (max-width: 950px) {
    .container {
      padding: 1rem;
      width: 25rem;
    }
  }

.accordion {
  .accordion-item {
    border-bottom: 1px solid $lightgray;
    button[aria-expanded="true"] {
      border-bottom: 1px solid $orange;
    }
  }
  button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: $text;
    font-size: 1.15rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
    &:hover,
    &:focus {
      cursor: pointer;
      color: $orange;
      &::after {
        cursor: pointer;
        color: $orange;
        border: 1px solid $orange;
      }
    }
    .accordion-title {
      padding: 1em 1.5em 1em 0;
    }
    .icon {
      display: inline-block;
      position: absolute;
      top: 18px;
      right: 0;
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-radius: 22px;
      &::before {
        display: block;
        position: absolute;
        content: "";
        top: 9px;
        left: 5px;
        width: 10px;
        height: 2px;
        background: currentColor;
      }
      &::after {
        display: block;
        position: absolute;
        content: "";
        top: 5px;
        left: 9px;
        width: 2px;
        height: 10px;
        background: currentColor;
      }
    }
  }
  button[aria-expanded="true"] {
    color: $orange;
    .icon {
      &::after {
        width: 0;
      }
    }
    + .accordion-content {
      opacity: 1;
      max-height: 9em;
      transition: all 200ms linear;
      will-change: opacity, max-height;
    }
  }
  .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
    p {
      font-size: 1rem;
      font-weight: 300;
      margin: 2em 0;
    }
  }

  @media screen and (max-width: 950px) {
    .container {
      padding: 1rem;
      width: 25rem;
    }
  }
    .container {
      padding: 1rem;
      width: 25rem;
    }


}
</style>